import loader from "./loader";
import moment from "moment";
// import _ from 'lodash'
import { secureStorage } from "libs";
import getInfo from "helper/getinfo";

const receipt = (payload) => {
  const printer = secureStorage.getItem("printer");
  const user = secureStorage.getItem("account");
  const info = secureStorage.getItem("domainInfo");
  const title = `${payload.title.padStart(105, " ")}\r\n`;
  const body = payload.body;
  // console.log(body);
  // const tp = body.filter(
  //   (item, index) => index >= body.length - 4 && index < body.length - 1
  // );

  const date =
    body.date && body.date.value ? body.date.value : payload.createdAt;
  const subscriberNo =
    body.subscriberNo && body.subscriberNo.caption
      ? [["IDPEL", body.subscriberNo.value]]
      : [];

  console.log({ payload });
  const leftPage = [
    // ["TGL", moment(date).format("DD/MM/YY HH:mm")],
    // [body.subscriberNo.caption, subscriberNo],
    ...subscriberNo,
    ["NOREG", body.regNo.value],
    [body.name.caption, body.name.value],
    ["TRAN", body.transaction.value],
    ["RP TAG", body.amount.value],
    ["ADMIN", body.admin.value],
    ["BAYAR", body.total.value],
  ];

  for (let i = leftPage.length; i < 11; i++) leftPage.push([""]);

  const middlePage = [
    // ["TANGGAL", moment(date).format("DD/MM/YY HH:mm")],
    // [body.subscriberNo.caption, subscriberNo],
    ...subscriberNo,
    ["NOREG", body.regNo.value],
    [body.name.caption, body.name.value],
    // [body.tranCode.caption, body.tranCode.value],
    [body.transaction.caption, body.transaction.value],
    [body.regDate.caption, body.regDate.value],
    // [body.expDate.caption, body.expDate.value],

    ...getInfo(body.info.value),
  ];
  // console.log([...body.filter((item,index)=>index<= Math.ceil((body.length-1)/2)-1),
  // [...body[body.length-1]],])
  // console.log(moment(body.date.value).format('DD/MM/YY HH:mm'))
  // middlePage.splice(0, 1, [
  //   "TANGGAL",
  //   moment(body.date.value).format("DD/MM/YY HH:mm"),
  // ]);
  // middlePage.splice(4, 1, ["KD TRAN", middlePage[4][1]]);
  // middlePage.splice(5, 1, ["TRAN", middlePage[5][1]]);
  // if(printer==='dotmatrix'){
  //   const noref = middlePage[5][1]
  //   middlePage.splice(5,1,['NO REF',noref.substring(0,15)])
  //   middlePage.splice(6,0,[noref.substring(15,noref.length)])
  // }else middlePage.splice(5,1,['NO REF',middlePage[5][1]])

  printer === "dotmatrix"
    ? middlePage.push([`${String.fromCharCode(27)}4Loket : ${user.name}`])
    : middlePage.push([`Loket : ${user.name}`]);
  printer === "dotmatrix"
    ? middlePage.push([
        `Powered by ${payload.appName}${String.fromCharCode(27)}5`,
      ])
    : middlePage.push([`Powered by ${payload.appName}`]);

  for (let i = middlePage.length; i < 11; i++) middlePage.push([""]);
  // console.log(middlePage)
  const rightPage = [
    // [body.servUnit.caption, body.servUnit.value],
    // [body.servUnitAddr.caption, body.servUnitAddr.value],
    // [body.servUnitPhone.caption, body.servUnitPhone.value],
    [body.referenceNo.caption, body.referenceNo.value],
    [body.amount.caption, body.amount.value],
    [body.admin.caption, body.admin.value],
    [body.total.caption, body.total.value],
    // ...body.filter(
    //   (item, index) =>
    //     index > Math.ceil((body.length - 1) / 2) - 1 && index < body.length - 1
    // ),
  ];
  // const token = rightPage[1]
  // rightPage.splice(1,1)
  // rightPage.push(['TOKEN',''])
  // printer==='dotmatrix' ?
  //   rightPage.push([`${String.fromCharCode(27)}E${String.fromCharCode(27)}4${token[1].padStart('25',' ')}${String.fromCharCode(27)}5${String.fromCharCode(27)}F`])
  //   :
  //   rightPage.push([`${token[1].padStart('25',' ')}`])
  // const swap = rightPage[0]
  // rightPage[0] = rightPage[1]
  // rightPage[1] = swap
  // rightPage.splice(0, 1, ["UNIT PEL", rightPage[0][1]]);
  // rightPage.splice(1, 1, ["ALAMAT", rightPage[1][1]]);
  // rightPage.splice(2, 1, ["TELP", rightPage[2][1]]);

  for (let i = rightPage.length; i < 11; i++) rightPage.push([""]);
  // middlePage.splice(0,0,[''])
  // rightPage.splice(0,0,[''])
  // console.log({leftPage,middlePage,rightPage})
  if (printer === "dotmatrix")
    return loader(title, leftPage, middlePage, rightPage, payload);
  else return { title, leftPage, middlePage, rightPage, payload };
};
export default receipt;
