import React from "react";

//hook styles class conversion
import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from "assets/jss/material-kit-pro-react/views/gMainMenuStyle.js";

import listrik from "assets/img/pln.png";
import paketData from "assets/img/paket-data.png";
import bpjs from "assets/img/bpjs.png";
import telkom from "assets/img/telkom2.jpg";
import emoney from "assets/img/emoney.jpg";
import multiFinance from "assets/img/multifinance.png"
import pbb from "assets/img/pbb1.png"
import pdam from "assets/img/pdam.png"
import tv from "assets/img/cable-tv.png"
import multi from "assets/img/multi.png"
import bankTransfer from "assets/img/transfer.png"
import kai from "assets/img/kai.jpg"

import Keys from "libs/keycodes"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import NavPills from "components/GNavPills/NavPills.js";

import ComplexButton from "../CustomComponents/ComplexButton.js";


// @material-ui/icons
import Shop from "@material-ui/icons/ShopTwo";
import HistoryIcon from "@material-ui/icons/History";

import History from 'views/Main/History'

import Zoom from '@material-ui/core/Zoom';

import SnackbarContent from "components/GSnackbar/SnackbarContent.js";
import Link from "@material-ui/core/Link";

import axios from 'axios'
import Config from 'config'
import secureStorage from 'libs/secureStorage'

class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      checked: false,
      btnName: '',
      verificationAlert: true,
      resend: '',
      changePage: false,
    };
    this.history = React.createRef()
  }
  componentDidMount() {
    this.setState({
      checked: true,
      user: secureStorage.getItem('account')
    });
    this.initVerification()
    document.addEventListener("keydown", this.handleKeydown)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeydown)
  }
  handleKeydown = (e) => {
    // console.log(e.keyCode)
    switch (e.keyCode) {
      case Keys.M:
        this.handleClick('MultiPayment')
    }
  }
  initVerification() {
    const user = secureStorage.getItem('account') ? secureStorage.getItem('account') : ''
    if (user.isVerified) {
      this.setState({ verificationAlert: false })
    }
  }

  handleClick = (btnName) => {
    this.setState({ btnName: btnName });
    this.setState({ checked: false });
  }
  handleVerificationClick = () => {
    secureStorage.setItem('ver', '1')
    this.setState({ btnName: 'MyProfile', checked: false });
  }
  handleExited = () => {
    this.props.onChangePage(this.state.btnName);
    // console.log(`/${this.state.btnName}`)
    // return <Redirect to={`/${this.state.btnName}`} />
    //this.setState({changePage:true})
    this.props.history.push(`/${this.state.btnName}`)
    //this.setState({checked:true});
  }
  handleNav = (active) => {
    if (active === 0) {
      this.history.current.unloadHistory()
    }
    else {
      this.history.current.loadHistory()
    }
  }
  handleAlertExit = (e) => {
    this.setState({ verificationAlert: false })
  }
  handleResend = (e) => {
    axios.post(Config.backendUrl + 'user/resend')
      .then(res => {
        this.setState({ resend: 'Terkirim' })
      }).catch(error => {
        console.log(error)
      });
  }
  render() {
    const { classes } = this.props;
    return (
      <Zoom in={this.state.checked} onExited={this.handleExited}>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container} >
              {
                this.state.verificationAlert ?
                  <SnackbarContent
                    message={
                      !this.state.resend ?
                        <span style={{ color: '#000' }}>
                          Account belum terverifikasi.
                          Link konfirmasi telah dikirim ke <b style={{ color: '#222' }}>{this.state.user && this.state.user.email}</b>.
                          Silahkan cek email dan ikuti link untuk melakukan konfirmasi.
                          Cek <b style={{ color: '#FF0000' }}>Spam</b> jika tidak ada di dalam Inbox/Kotak Masuk.
                          Klik <Link component="button" onClick={this.handleVerificationClick} style={{ color: '#0000FF', fontWeight: 'bold' }}>disini</Link> untuk mengganti email.
                          Jika belum terkirim silahkan klik <Link component="button" onClick={this.handleResend} style={{ color: '#0000FF', fontWeight: 'bold' }}>kirim ulang</Link>.
                        </span>
                        : <span style={{ color: '#000' }}>
                          Email telah dikirim ulang.
                        </span>
                    }
                    onExited={this.handleAlertExit}
                    close
                    color="warning"
                    icon="info_outline"
                  /> : null
              }
              {/* {
              !secureStorage.getItem('username') && 
              <SnackbarContent
								message={
									<span style={{color:'#000'}}>
                    Fitur baru <b style={{color:'#FF0000'}}>PIN</b>.  
                    Klik <Link component="button" onClick={(e)=>{
                      secureStorage.setItem('settings','1')
                      this.setState({btnName:'Settings',checked:false});
                    }} style={{color:'#0000FF',fontWeight:'bold'}}>disini</Link> intuk mengaktifkan PIN &amp; Login menggukan PIN.
                  </span>
                }
								onExited = {this.handleAlertExit}
								close
								color="warning"
								icon="info_outline"
							/>
            } */}
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} className={classes.navWrapper}>
                  <NavPills
                    alignCenter
                    onChange={this.handleNav}
                    color="facebook"
                    tabs={[
                      {
                        tabButton: "Main Menu",
                        tabIcon: Shop,
                        tabContent: (
                          <GridContainer className={classes.gridWrapper}>
                            <GridItem xs={6} sm={3} md={2}>
                              <ComplexButton
                                onClick={this.handleClick}
                                id="Listrik"
                                title="LISTRIK"
                                image={listrik}
                                width="80px"
                              />
                            </GridItem>
                            <GridItem xs={6} sm={3} md={2}>
                              <ComplexButton
                                onClick={this.handleClick}
                                id="Bpjs"
                                title="BPJS"
                                image={bpjs}
                                width="80px"
                              />
                            </GridItem>
                            {/* <GridItem xs={6} sm={3} md={2}>
                              <ComplexButton 
                                onClick={this.handleClick}
                                id="PaketData"
                                title="PULSA"
                                image={paketData}
                                width="80px"
                              />
                              
                            </GridItem> */}
                            <GridItem xs={6} sm={3} md={2}>
                              <ComplexButton
                                onClick={this.handleClick}
                                id="Pulsa"
                                title="PULSA"
                                image={paketData}
                                width="80px"
                              />
                            </GridItem>
                            {/* <GridItem xs={6} sm={3} md={2}>
                              <ComplexButton 
                                onClick={this.handleClick}
                                id="Postpaid"
                                title="Postpaid"
                                image={postpaid}
                                width="80px"
                              />
                            </GridItem> */}
                            <GridItem xs={6} sm={3} md={2}>
                              <ComplexButton
                                onClick={this.handleClick}
                                id="Telkom"
                                title="TELKOM"
                                image={telkom}
                                width="80px"
                              />
                            </GridItem>
                            <GridItem xs={6} sm={3} md={2}>
                              <ComplexButton
                                onClick={this.handleClick}
                                id="Emoney"
                                title="E-WALLET"
                                image={emoney}
                                width="80px"
                              />
                            </GridItem>
                            <GridItem xs={6} sm={3} md={2}>
                              <ComplexButton
                                onClick={this.handleClick}
                                id="MultiFinance"
                                title="MULTI FINANCE"
                                image={multiFinance}
                                width="80px"
                              />
                            </GridItem>
                            <GridItem xs={6} sm={3} md={2}>
                              <ComplexButton
                                onClick={this.handleClick}
                                id="PBB"
                                title="PBB"
                                image={pbb}
                                width="80px"
                              />
                            </GridItem>
                            <GridItem xs={6} sm={3} md={2}>
                              <ComplexButton
                                onClick={this.handleClick}
                                id="Pdam"
                                title="PDAM"
                                image={pdam}
                                width="80px"
                              />
                            </GridItem>
                            <GridItem xs={6} sm={3} md={2}>
                              <ComplexButton
                                onClick={this.handleClick}
                                id="Tv"
                                title="TV KABEL"
                                image={tv}
                                width="80px"
                              />
                            </GridItem>
                            {/* <GridItem xs={6} sm={3} md={2}>
                              <ComplexButton 
                                onClick={this.handleClick}
                                id="BankTransfer"
                                title="TRANSFER BANK"
                                image={bankTransfer}
                                width="80px"
                              />
                            </GridItem>
                            <GridItem xs={6} sm={3} md={2}>
                              <ComplexButton 
                                onClick={this.handleClick}
                                id="Kai"
                                title="KERETA API"
                                image={kai}
                                width="80px"
                              />
                            </GridItem>
                            <GridItem xs={6} sm={3} md={2}>
                              <ComplexButton 
                                onClick={this.handleClick}
                                id="MultiPayment"
                                title="MULTI PAYMENT"
                                image={multi}
                                width="80px"
                              />
                            </GridItem> */}
                          </GridContainer>
                        )
                      },
                      {
                        tabButton: "History",
                        tabIcon: HistoryIcon,
                        tabContent: (
                          <GridContainer justify="center" className={classes.gridWrapper}>
                            <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
                              <History ref={this.history} />
                            </GridItem>
                          </GridContainer>
                        )
                      },

                    ]}
                  />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </Zoom>
    );
  }
}
MainMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(MainMenu);