import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/gTransactionStyle.js";

// @material-ui/icons
import Print from "@material-ui/icons/Print";
import Share from "@material-ui/icons/Share";

// core components
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Pay from "@material-ui/icons/Payment";

import Slide from "@material-ui/core/Slide";
import Input from "components/GCustomInput/CustomInput.js";

import Accordion from "components/GAccordion/Accordion.js";

import axios from "axios";
import Config from "config";

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { fnumber } from "helper/Helper";
import modalReceipt from "helper/modalReceipt";
import print from "helper/printer";

import share from "libs/share";
//import { savePdf} from "libs/pdf"

import _ from "lodash";

import Indihome from "./Indihome";

import moment from "moment";

import { Close, CloudDownload as Download } from "@material-ui/icons";

/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class Telkom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      direction: "left",
      expanded: "panel1",
      log: "",

      noTelp: "",
      product: null,

      filename: "",

      buttonDisabled: false,

      isRequestError: false,
      confirmationModal: false,
      isInquiry: true,

      statusPrint: false,
    };
  }

  componentDidMount() {
    this.setState({ checked: true });
    this.loadProduct();
  }

  loadProduct = () => {
    axios
      .post(Config.backendUrl + "product/byid", {
        productId: 9,
      })
      .then((res) => {
        //console.log(res.data)
        this.setState({ product: res.data });
      })
      .catch((err) => {});
  };

  handleBack = () => {
    this.setState({ checked: false, direction: "right" });
  };
  handleExited = () => {
    this.props.history.push("/MainMenu");
  };

  handleDropdown = (e) => {
    //this.setState({log:e});
  };
  handleChangeExpansion = (e) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? e : false });
  };

  handleSelect = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSubmitInquiry = (target) => {
    //console.log(target +'-'+ this.state.noTelp+' ' +this.state.nominalPre+ ' '+this.state.adminPre )

    if (target === "telkom") {
      if (this.state.noTelp && this.state.product) {
        // const splitter = this.state.noTelp.split('-')
        // const noTelp = splitter.length>1 ? splitter[0].replace(/\d+/g, function(m){
        //   return "0000".substr(m.length) + m
        // }) + splitter[1].replace(/\d+/g, function(m){
        //   return "000000000".substr(m.length) + m
        // }):this.state.noTelp.padStart(13,0)
        const noTelp = _.replace(this.state.noTelp, "-", "");
        const payload = {
          customerCode: noTelp,
          //nominal : Nominal[this.state.nominalPre].value,
          adminNominal: this.state.product.AdminNominals[0].nominal,
          productId: this.state.product.id, //prepaid
        };
        this.setState({ buttonDisabled: true });
        //this.setState({confirmationModal:true})
        // console.log(payload)
        this.request(payload, "inquiry");
      }
    }
  };
  setModal(data, isError) {
    if (!isError) {
      this.setState({
        statusPrint: data.statusId === 4 ? true : false,
        modalTitle: data.receiptTitle,
        modalBody: data.receiptBody,
        confirmationModal: true,
        filename: `${data.productName}_${data.customerCode}_${moment(
          data.receiptBody.date.value
        ).format("YYMMDDHHmm")}.pdf`,
      });
    } else {
      this.setState({
        statusPrint: data.statusId === 4 ? true : false,
        modalTitle: data.receiptTitle,
        modalBody: data.receiptBody,
        confirmationModal: true,
      });
    }
  }
  handleChange = (e) => {
    this.setNoTelp(e.target.value);
  };
  handleNumpad = (target, value) => {
    this.setNoTelp(value);
    //this.setState({[target]:""+value})
  };

  setNoTelp = (value) => {
    //console.log(e.target.value)
    // let s = "1200"
    // s = "Rp. " + s.replace(/\d+/g, function(m){
    //   // console.log(m)
    //   return "000000000".substr(m.length +1) + fnumber(m);
    // });
    // console.log(s)
    if (value.length >= 3 && value.indexOf("-") === -1) {
      //console.log(value.substring(0,3))
      //console.log(value.substring(0,4))
      if (
        this.state.noTelp &&
        this.state.noTelp.length === 5 &&
        this.state.noTelp[4] === "-"
      ) {
        // console.log('test')
      } else if (
        this.state.noTelp &&
        this.state.noTelp.length === 4 &&
        this.state.noTelp[3] === "-"
      ) {
        // console.log('test')
      } else {
        if (Area.indexOf(value.substring(0, 3)) !== -1) {
          value =
            value.substring(0, 3) + "-" + value.substring(3, value.length);
        } else if (Area.indexOf(value.substring(0, 4)) !== -1) {
          value =
            value.substring(0, 4) + "-" + value.substring(4, value.length);
        }
      }
    }

    this.setState({ noTelp: value });
  };

  handleModal = (e) => {
    if (e === "confirm") {
      const payload = {
        ...this.state.purchasePayload,
      };
      this.request(payload, "purchase");
      //console.log(this.state.purchasePayload)
    } else if (e === "cancel" || e === "close" || e === "error") {
      this.setState({
        statusPrint: false,
        confirmationModal: false,
        isInquired: false,
        isRequestError: false,
        noTelp: "",

        buttonDisabled: false,
      });
    } else if (e === "print") {
      const title = this.state.modalTitle;
      const body = this.state.modalBody;
      const filename = this.state.filename;
      print({
        title,
        body,
        filename,
        ...this.state.products[this.state.productDropdown],
      });
      this.setState({
        statusPrint: false,
        confirmationModal: false,
        isInquired: false,
        isRequestError: false,
        noTelp: "",

        buttonDisabled: false,
      });
    }
  };

  request(payload, urlQuery) {
    this.setState({ confirmationModal: false });
    axios
      .post(Config.backendUrl + "request/" + urlQuery, payload)
      .then((res) => {
        if (res.data) {
          //console.log(res.data)
          this.setModal(res.data, false);
          if (urlQuery === "inquiry") {
            let data = res.data;
            delete data.receiptTitle;
            delete data.receiptBody;
            this.setState({
              isInquiry: true,
              purchasePayload: data,
            });
          } else if (urlQuery === "purchase") {
            this.setState({
              isInquiry: false,
              purchasePayload: "",
            });
          }
        }
        //alert('test1')
      })
      .catch((err) => {
        this.setState({ isRequestError: true });
        console.log(err.response);
        if (err.response) {
          if (!err.response.data.receiptTitle)
            this.setModal(
              {
                receiptTitle: err.response.statusText,
                receiptBody: [err.response.data],
              },
              true
            );
          else this.setModal(err.response.data, true);
        } else
          this.setModal(
            { receiptTitle: "Error", receiptBody: ["Network Error"] },
            true
          );
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <Slide
        direction={this.state.direction}
        in={this.state.checked}
        mountOnEnter
        unmountOnExit
        onExited={this.handleExited}
      >
        <div className={classNames(classes.main, classes.mainRaised)}>
          <Button
            color="google"
            round
            className={classes.backButton}
            onClick={this.handleBack}
          >
            <ArrowBackIos /> Back
          </Button>
          <div className={classes.container}>
            <Accordion
              activeColor="behance"
              active={0}
              collapses={[
                {
                  title: "Telkom PSTN",
                  content: (
                    <div className={classes.section}>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={7}
                          className={classes.grid}
                        >
                          {
                            //IdPel
                          }
                          <Input
                            labelText="No Telp / IDPel"
                            id="noTelp"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              placeholder: "Masukkan No Telp / ID Pelanggan",
                              value: this.state.noTelp,
                              onChange: this.handleChange,
                              type: "tel",
                            }}
                          />
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={7}
                          className={classes.grid}
                          style={{ paddingTop: 15 }}
                        >
                          {!this.state.buttonDisabled ? (
                            <Button
                              id="telkom"
                              color="instagram"
                              round
                              onClick={() => this.handleSubmitInquiry("telkom")}
                            >
                              <Pay />
                              Submit
                            </Button>
                          ) : (
                            <CircularProgress color="secondary" />
                          )}
                        </GridItem>
                      </GridContainer>
                    </div>
                  ),
                },
                {
                  title: "Indihome",
                  content: <Indihome />,
                },
              ]}
            />
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal,
              }}
              open={this.state.confirmationModal}
              TransitionComponent={Transition}
              keepMounted
              //onClose={() => this.setState({successModal: false})}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                {this.state.modalBody && modalReceipt(this.state.modalBody)}
              </DialogContent>
              {this.state.isRequestError ? (
                <DialogActions className={classes.modalFooter}>
                  <Button
                    round
                    onClick={() => this.handleModal("error")}
                    color="danger"
                  >
                    Ok
                  </Button>
                </DialogActions>
              ) : this.state.isInquiry ? (
                <DialogActions className={classes.modalFooter}>
                  <Button
                    round
                    onClick={() => this.handleModal("cancel")}
                    color="danger"
                  >
                    Cancel
                  </Button>
                  <Button
                    round
                    onClick={() => this.handleModal("confirm")}
                    color="instagram"
                  >
                    Confirm
                  </Button>
                </DialogActions>
              ) : (
                <DialogActions className={classes.modalFooter}>
                  <Button
                    round
                    onClick={() => this.handleModal("close")}
                    color="danger"
                  >
                    <Close />
                    Close
                  </Button>
                  {this.state.statusPrint && (
                    <Button
                      round
                      onClick={() => this.handleModal("print")}
                      color="instagram"
                    >
                      <Print />
                      Print
                    </Button>
                  )}
                  {this.state.modalBody && this.state.statusPrint && (
                    <Button
                      round
                      justIcon
                      onClick={() => {
                        const { savePdf } = require("libs/pdf");
                        savePdf(
                          {
                            title: this.state.modalTitle,
                            body: this.state.modalBody,
                            filename: this.state.filename,
                          },
                          "PdfReceipt"
                        );
                      }}
                      color="success"
                    >
                      <Download />
                    </Button>
                  )}
                  {navigator.canShare &&
                    this.state.modalBody &&
                    this.state.statusPrint && (
                      <Button
                        round
                        justIcon
                        onClick={async () =>
                          share({
                            title: this.state.modalTitle,
                            body: this.state.modalBody,
                            filename: this.state.filename,
                          })
                        }
                        color="info"
                      >
                        <Share />
                      </Button>
                    )}
                </DialogActions>
              )}
            </Dialog>
          </div>
        </div>
      </Slide>
    );
  }
}
Telkom.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Telkom);

const Area = [
  "0627",
  "0629",
  "0641",
  "0642",
  "0643",
  "0644",
  "0645",
  "0646",
  "0650",
  "0651",
  "0652",
  "0653",
  "0654",
  "0655",
  "0656",
  "0657",
  "0658",
  "0659",
  "061",
  "0620",
  "0621",
  "0622",
  "0623",
  "0624",
  "0625",
  "0626",
  "0627",
  "0628",
  "0630",
  "0631",
  "0632",
  "0633",
  "0634",
  "0635",
  "0636",
  "0639",
  "0751",
  "0752",
  "0753",
  "0754",
  "0755",
  "0756",
  "0757",
  "0760",
  "0761",
  "0762",
  "0763",
  "0764",
  "0765",
  "0766",
  "0767",
  "0768",
  "0769",
  "0624",
  "0770",
  "0771",
  "0772",
  "0773",
  "0776",
  "0777",
  "0778",
  "0779",
  "0740",
  "0741",
  "0742",
  "0743",
  "0744",
  "0745",
  "0746",
  "0747",
  "0748",
  "0702",
  "0711",
  "0712",
  "0713",
  "0714",
  "0730",
  "0731",
  "0733",
  "0734",
  "0735",
  "0715",
  "0716",
  "0717",
  "0718",
  "0719",
  "0732",
  "0736",
  "0737",
  "0738",
  "0739",
  "0721",
  "0722",
  "0723",
  "0724",
  "0725",
  "0726",
  "0727",
  "0728",
  "0729",
  "0252",
  "0253",
  "0254",
  "0257",
  "021",
  "022",
  "0231",
  "0232",
  "0233",
  "0234",
  "0251",
  "0260",
  "0261",
  "0262",
  "0263",
  "0264",
  "0265",
  "0266",
  "0267",
  "024",
  "0271",
  "0272",
  "0273",
  "0274",
  "0275",
  "0276",
  "0280",
  "0281",
  "0282",
  "0283",
  "0284",
  "0285",
  "0286",
  "0287",
  "0289",
  "0291",
  "0292",
  "0293",
  "0294",
  "0295",
  "0296",
  "0297",
  "0298",
  "0299",
  "0356",
  "0274",
  "031",
  "0321",
  "0322",
  "0323",
  "0324",
  "0325",
  "0327",
  "0328",
  "0331",
  "0332",
  "0333",
  "0334",
  "0335",
  "0336",
  "0338",
  "0341",
  "0342",
  "0343",
  "0351",
  "0352",
  "0353",
  "0354",
  "0355",
  "0356",
  "0357",
  "0358",
  "0361",
  "0362",
  "0363",
  "0365",
  "0366",
  "0368",
  "0364",
  "0370",
  "0371",
  "0372",
  "0373",
  "0374",
  "0376",
  "0380",
  "0381",
  "0382",
  "0383",
  "0384",
  "0385",
  "0386",
  "0387",
  "0388",
  "0389",
  "0561",
  "0562",
  "0563",
  "0564",
  "0565",
  "0567",
  "0568",
  "0534",
  "0513",
  "0522",
  "0525",
  "0526",
  "0528",
  "0531",
  "0532",
  "0536",
  "0537",
  "0538",
  "0539",
  "0511",
  "0512",
  "0517",
  "0518",
  "0526",
  "0527",
  "0541",
  "0542",
  "0543",
  "0545",
  "0548",
  "0549",
  "0554",
  "0551",
  "0552",
  "0553",
  "0556",
  "0430",
  "0431",
  "0432",
  "0434",
  "0438",
  "0435",
  "0443",
  "0445",
  "0450",
  "0451",
  "0452",
  "0453",
  "0454",
  "0457",
  "0458",
  "0461",
  "0462",
  "0463",
  "0464",
  "0465",
  "0455",
  "0422",
  "0426",
  "0428",
  "0410",
  "0411",
  "0413",
  "0414",
  "0417",
  "0418",
  "0419",
  "0420",
  "0421",
  "0423",
  "0427",
  "0471",
  "0472",
  "0473",
  "0474",
  "0475",
  "0481",
  "0482",
  "0484",
  "0485",
  "0401",
  "0402",
  "0403",
  "0404",
  "0405",
  "0408",
  "0910",
  "0911",
  "0913",
  "0914",
  "0915",
  "0916",
  "0917",
  "0918",
  "0921",
  "0922",
  "0923",
  "0924",
  "0927",
  "0929",
  "0931",
  "0901",
  "0902",
  "0951",
  "0952",
  "0955",
  "0956",
  "0957",
  "0966",
  "0967",
  "0969",
  "0971",
  "0975",
  "0980",
  "0981",
  "0983",
  "0984",
  "0985",
  "0986",
];
