import moment from "moment";
import loader from "./loader";
// import _ from 'lodash'
import secureStorage from "libs/secureStorage";
import getinfo from "helper/getinfo";

const receipt = (payload) => {
    const printer = secureStorage.getItem("printer");
    const user = secureStorage.getItem("account");
    const info = secureStorage.getItem("domainInfo");
    const title = `${payload.title.padStart(105, " ")}\r\n`;
    const body = payload.body;

    const leftPage = [
        ["TGL", moment(body.date.value).format("DD/MM/YY HH:mm")],
        ["NO HP", body.phoneNumber.value],
        ["ITEM", body.item.value],
        ["NO REF", body.referenceNo.value],
        ["DENOM", body.denom.value],
        ["RP BAYAR", body.total.value],
    ];


    for (let i = leftPage.length; i < 11; i++) leftPage.push([""]);

    const middlePage = [
        ["TANGGAL", moment(body.date.value).format("DD/MM/YY HH:mm")],
        ["NO HP", body.phoneNumber.value],
        ["ITEM", body.item.value],

        ["NO REF", body.referenceNo.value],
        ["DENOM", body.denom.value],
        ["RP BAYAR", body.total.value],

        ...getinfo("SIMPAN TANDA TERIMA INI SEBAGAI BUKTI TRANSAKSI TERIMA KASIH"),
    ];

    printer === "dotmatrix"
        ? middlePage.push([`${String.fromCharCode(27)}4Loket : ${user.name}`])
        : middlePage.push([`Loket : ${user.name}`]);
    printer === "dotmatrix"
        ? middlePage.push([
            `Powered by ${payload.appName}${String.fromCharCode(27)}5`,
        ])
        : middlePage.push([`Powered by ${payload.appName}`]);

    for (let i = middlePage.length; i < 11; i++) middlePage.push([""]);

    const rightPage = [
        [body.date.caption, moment(body.date.value).format("DD/MM/YY HH:mm")],
        [body.phoneNumber.caption, body.phoneNumber.value],
        [body.item.caption, body.item.value],
        [
            body.referenceNo.caption,
            body.referenceNo.value.toString().substring(0, 15),
        ],
        [body.denom.caption, body.denom.value],
        [body.total.caption, body.total.value],
    ];

    for (let i = rightPage.length; i < 11; i++) rightPage.push([""]);

    if (printer === "dotmatrix")
        return loader(title, leftPage, middlePage, rightPage, payload);
    else return { title, leftPage, middlePage, rightPage, payload };
}

export default receipt;