import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/gTransactionStyle.js";

// @material-ui/icons
import Print from "@material-ui/icons/Print";
import Share from "@material-ui/icons/Share";

// core components
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Pay from "@material-ui/icons/Payment";

import Slide from "@material-ui/core/Slide";
import Input from "components/GCustomInput/CustomInput.js";

// Custom select
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Accordion from "components/GAccordion/Accordion.js";

import axios from "axios";
import Config from "config";

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import modalReceipt from "helper/modalReceipt";
import print from "helper/printer";

import share from "libs/share";
//import { savePdf} from "libs/pdf"

import moment from "moment";

import { Close, CloudDownload as Download } from "@material-ui/icons";
/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class Bpjs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      direction: "left",
      expanded: "panel1",
      log: "",

      noBpjs: "",

      productDropdown: "",
      products: [],

      selectedMonth: 1,

      buttonDisabled: false,

      filename: "",

      isRequestError: false,
      confirmationModal: false,
      isInquiry: true,

      statusPrint: false,
    };
  }

  componentDidMount() {
    this.setState({ checked: true });
    this.loadProduct();
  }

  loadProduct = () => {
    axios
      .post(Config.backendUrl + "product/like/dropdown", {
        productNameLike: "BPJS",
      })
      .then((res) => {
        // console.log(res.data)
        this.setState({ products: res.data });
      })
      .catch((err) => {});
  };

  handleBack = () => {
    this.setState({ checked: false, direction: "right" });
  };
  handleExited = () => {
    this.props.history.push("/MainMenu");
  };
  handleChangeExpansion = (e) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? e : false });
  };
  handleSelect = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSubmitInquiry = (target) => {
    if (target === "bpjs") {
      if (
        this.state.noBpjs &&
        this.state.productDropdown !== "" &&
        this.state.selectedMonth >= 0 &&
        this.state.selectedMonth <= 12
      ) {
        const payload = {
          customerCode: this.state.noBpjs,
          month: this.state.selectedMonth,
          adminNominal: this.state.products[this.state.productDropdown]
            .AdminNominals[0].nominal,
          productId: this.state.products[this.state.productDropdown].id,
        };
        this.setState({ buttonDisabled: true });
        this.request(payload, "inquiry");
      }
    }
  };
  setModal(data, isError) {
    if (!isError) {
      this.setState({
        statusPrint: data.statusId === 4 ? true : false,
        modalTitle: data.receiptTitle,
        modalBody: data.receiptBody,
        confirmationModal: true,
        filename: `${data.productName}_${data.customerCode}_${moment(
          data.receiptBody.date.value
        ).format("YYMMDDHHmm")}.pdf`,
      });
    } else {
      this.setState({
        statusPrint: data.statusId === 4 ? true : false,
        modalTitle: data.receiptTitle,
        modalBody: data.receiptBody,
        confirmationModal: true,
      });
    }
  }

  handleModal = (e) => {
    if (e === "confirm") {
      const payload = {
        ...this.state.purchasePayload,
      };
      this.request(payload, "purchase");
      //console.log(this.state.purchasePayload)
    } else if (e === "cancel" || e === "close" || e === "error") {
      this.setState({
        statusPrint: false,
        confirmationModal: false,
        isInquired: false,
        isRequestError: false,
        noBpjs: "",
        productDropdown: "",
        selectedMonth: 1,

        buttonDisabled: false,
      });
    } else if (e === "print") {
      const title = this.state.modalTitle;
      const body = this.state.modalBody;
      const filename = this.state.filename;
      print({
        title,
        body,
        filename,
        ...this.state.products[this.state.productDropdown],
      });
      this.setState({
        statusPrint: false,
        confirmationModal: false,
        isInquired: false,
        isRequestError: false,
        noBpjs: "",
        productDropdown: "",
        selectedMonth: 1,

        buttonDisabled: false,
      });
    }
  };

  request(payload, urlQuery) {
    this.setState({ confirmationModal: false });
    axios
      .post(Config.backendUrl + "request/" + urlQuery, payload)
      .then((res) => {
        if (res.data) {
          //console.log(res.data)
          this.setModal(res.data, false);
          if (urlQuery === "inquiry") {
            let data = res.data;
            delete data.receiptTitle;
            delete data.receiptBody;
            this.setState({
              isInquiry: true,
              purchasePayload: data,
            });
          } else if (urlQuery === "purchase") {
            this.setState({
              isInquiry: false,
              purchasePayload: "",
            });
          }
        }
        //alert('test1')
      })
      .catch((err) => {
        this.setState({ isRequestError: true });
        console.log(err.response);
        if (err.response) {
          if (!err.response.data.receiptTitle)
            this.setModal(
              {
                receiptTitle: err.response.statusText,
                receiptBody: [err.response.data],
              },
              true
            );
          else this.setModal(err.response.data, true);
        } else
          this.setModal(
            { receiptTitle: "Error", receiptBody: ["Network Error"] },
            true
          );
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <Slide
        direction={this.state.direction}
        in={this.state.checked}
        mountOnEnter
        unmountOnExit
        onExited={this.handleExited}
      >
        <div className={classNames(classes.main, classes.mainRaised)}>
          <Button
            color="google"
            round
            className={classes.backButton}
            onClick={this.handleBack}
          >
            <ArrowBackIos /> Back
          </Button>
          <div className={classes.container}>
            <Accordion
              activeColor="behance"
              active={0}
              collapses={[
                {
                  title: "BPJS",
                  content: (
                    <div className={classes.section}>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={7}
                          className={classes.grid}
                        >
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="product-select"
                              className={classes.selectLabel}
                            >
                              Item
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.productDropdown}
                              onChange={this.handleSelect}
                              inputProps={{
                                name: "productDropdown",
                                id: "productDropdown",
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                                value={""}
                              >
                                Item
                              </MenuItem>
                              {this.state.products.map((product, index) => (
                                <MenuItem
                                  key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={index}
                                >
                                  {product.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={7}
                          className={classes.grid}
                          style={{ paddingTop: 8 }}
                        >
                          <Input
                            labelText="No VA"
                            id="noBpjs"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              placeholder: "Masukkan Nomor Virtual Account",
                              value: this.state.noBpjs,
                              onChange: (e) => {
                                this.setState({ noBpjs: e.target.value });
                              },
                              type: "tel",
                            }}
                          />
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={7}
                          className={classes.grid}
                        >
                          <Input
                            labelText="JUMLAH PERIODE"
                            id="selectedMonth"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              placeholder: "Masukkan Jumlah Periode Pembayaran",
                              value: this.state.selectedMonth,
                              onChange: (e) => {
                                let selectedMonth =
                                  e.target.value === "" ? 0 : e.target.value;

                                if (selectedMonth < 0) selectedMonth = 0;
                                if (selectedMonth > 12) selectedMonth = 12;

                                this.setState({
                                  selectedMonth: parseInt(selectedMonth),
                                });
                              },
                              type: "number",
                            }}
                          />
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={7}
                          className={classes.grid}
                        >
                          {!this.state.buttonDisabled ? (
                            <Button
                              id="bpjs"
                              color="instagram"
                              round
                              onClick={() => this.handleSubmitInquiry("bpjs")}
                            >
                              <Pay />
                              Submit
                            </Button>
                          ) : (
                            <CircularProgress color="secondary" />
                          )}
                        </GridItem>
                      </GridContainer>
                    </div>
                  ),
                },
              ]}
            />
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal,
              }}
              open={this.state.confirmationModal}
              TransitionComponent={Transition}
              keepMounted
              //onClose={() => this.setState({successModal: false})}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                {this.state.modalBody && modalReceipt(this.state.modalBody)}
              </DialogContent>
              {this.state.isRequestError ? (
                <DialogActions className={classes.modalFooter}>
                  <Button
                    round
                    onClick={() => this.handleModal("error")}
                    color="danger"
                  >
                    Ok
                  </Button>
                </DialogActions>
              ) : this.state.isInquiry ? (
                <DialogActions className={classes.modalFooter}>
                  <Button
                    round
                    onClick={() => this.handleModal("cancel")}
                    color="danger"
                  >
                    Cancel
                  </Button>
                  <Button
                    round
                    onClick={() => this.handleModal("confirm")}
                    color="instagram"
                  >
                    Confirm
                  </Button>
                </DialogActions>
              ) : (
                <DialogActions className={classes.modalFooter}>
                  <Button
                    round
                    onClick={() => this.handleModal("close")}
                    color="danger"
                  >
                    <Close />
                    Close
                  </Button>
                  {this.state.statusPrint && (
                    <Button
                      round
                      onClick={() => this.handleModal("print")}
                      color="instagram"
                    >
                      <Print />
                      Print
                    </Button>
                  )}
                  {this.state.modalBody && this.state.statusPrint && (
                    <Button
                      round
                      justIcon
                      onClick={() => {
                        const { savePdf } = require("libs/pdf");
                        savePdf(
                          {
                            title: this.state.modalTitle,
                            body: this.state.modalBody,
                            filename: this.state.filename,
                          },
                          "PdfReceipt"
                        );
                      }}
                      color="success"
                    >
                      <Download />
                    </Button>
                  )}
                  {navigator.canShare &&
                    this.state.modalBody &&
                    this.state.statusPrint && (
                      <Button
                        round
                        justIcon
                        onClick={async () =>
                          share({
                            title: this.state.modalTitle,
                            body: this.state.modalBody,
                            filename: this.state.filename,
                          })
                        }
                        color="info"
                      >
                        <Share />
                      </Button>
                    )}
                </DialogActions>
              )}
            </Dialog>
          </div>
        </div>
      </Slide>
    );
  }
}
Bpjs.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Bpjs);
